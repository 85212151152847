:root {
  /* Palette */
  --gold-color: #ffd700;
  --white-color: #fff;
  --black-color: #000000;
  --grey-color: #cccccc;
  --lightgrey-color: #cccccc7f;
  --blue-color: #0000ff;
  --purple-color: #800080;
}

body {
  font-family: 'Avenir';
  font-size: 16px;
  color: var(--black-color)
}

.app {
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--gold-color);
  padding: 20px 50px;
}

.logo {
  width: 4rem;
  height: 4rem;
  background-image: url(./../public/images/vp-logo.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title-fullname {
  font-size: 2.5rem;
  font-weight:600;
}

.title-traits {
  font-size: 1.5rem;
}

.connect-container{
  min-width: 232px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
}

.connect-item {
  width:48px;  
  height:48px;  
  margin: 5px;
  cursor: pointer;
}

.connect-item:hover {
  transform: scale(1.1);
}

.connect-channel {
  background-image: url(./../public/images/youtube.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.connect-linkedin {
  background-image: url(./../public/images/linkedin.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.connect-twitter {
  background-image: url(./../public/images/twitter.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.connect-mail {
  background-image: url(./../public/images/mail.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-container {
  min-height: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--gold-color);
  padding: 0px 50px 0px 50px;
} 

.banner-quote {
  width: 80%;
  text-align: center;
  font-size:  2rem;
}

.program-title {
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: .25rem;
  padding: 50px;
}

.program-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px;
  padding: 0px 50px 0px 50px;
}

.program-card {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 250px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #ccc;
}

.program-name {
  font-weight: 600;
  text-align: left;
  margin: 30px 0px 20px 20px;
}

.program-desc {
  height: 80%;
  margin: 10px 20px 20px 20px;
  text-align:left;
  line-height: 25px;
}

.program-sub {
  width: 100%;
  height: 40px;
  line-height: 40px;
  align-self: self-end;
  text-align: left;
  border-radius: 0px 0px 8px 8px;
}

.program-sub-link {
  text-decoration: none;
  color: var(--purple-color);
  margin-left: 20px;
}

.consulting-title {
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: .25rem;
  margin-top: 40px;
  padding: 50px;
}

.consulting-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 50px;
  padding: 0px 50px 0px 50px;
}

.consulting-item {
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.consulting-area {
  min-width: 200px;
  width: 30%;
  text-align: center;
  font-size: 1.75rem;
}

.consulting-desc {
  min-width: 300px;
  width: 40%;
  text-align: left;
}

.footer-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--lightgrey-color);
  margin-top: 20px;
} 

.footer-profile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 50px 20px 50px;
  gap:20px;
}

.footer-profile-photo {
  width: 120px;
  height: 120px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-image:  url(./../public/images/vinod-purushothaman.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-profile-info {
  width: 70%;
}

.footer-copyright{
  width: 100%;
  background-color: var(--grey-color);
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 500px) {
  .header-container {
    justify-content: center;
    padding: 20px;
  }

  .title-container {
    align-items: center;
  }

  .title-fullname {
    font-size: 1.75rem;
    font-weight:600;
  }
  
  .title-traits {
    font-size: 1rem;
  }

  .banner-container {
    min-height: 100px;
    padding: 20px;
  }

  .banner-quote {
    font-size: 1.25rem;
  }

  .program-title{
    padding: 20px;
  }

  .program-container {
    padding: 0px 20px 0px 20px;
  }


  .consulting-title{
    padding: 20px;
  }

  .consulting-container {
    padding: 0px 20px 0px 20px;
  }

  .footer-profile-container {
    padding: 20px;
  }

  .footer-profile-info {
    width: 100%;
  }
}